<template>
    <div>
        <v-sheet class="dense-inputs">
            <v-row no-gutters>
                <div class="d-flex align-center pt-3">
                    <v-text-field
                        :placeholder="$t('message.poNo')"
                        :value="searchValues.title"
                        autocomplete="new-password"
                        class="filter-width-175 force-text-left"
                        clearable
                        dense
                        hide-details="auto"
                        append-icon="search"
                        solo
                        @change="searchValues.title = $event"
                        @click:append="searchPurchaseOrders('title')"
                        @click:clear="resetSearch()"
                        @keyup.enter.prevent="searchPurchaseOrders('title')"
                    ></v-text-field>
                    <v-select
                        :items="filterItems.status"
                        :loading="loading.filterItems.status"
                        :placeholder="$t('message.status')"
                        class="filter-width-150 ml-2"
                        dense
                        hide-details="auto"
                        item-text="Status.title"
                        item-value="Status.id"
                        solo
                        v-model="filterValues.status"
                        @change="filterPurchaseOrders()"
                    >
                        <template v-slot:item="{ item }">
                            <span class="font-sm">{{ $t('message.' + item.Status.title) }}</span>
                        </template>
                        <template v-slot:selection="{ item }">
                            <span class="font-sm">{{ $t('message.' + item.Status.title) }}</span>
                        </template>
                    </v-select>
                    <v-autocomplete
                        :items="filterItems.buyer"
                        :loading="loading.filterItems.buyer"
                        :placeholder="$t('message.buyer')"
                        class="filter-width-175 ml-2"
                        clearable
                        dense
                        hide-details="auto"
                        item-text="Customer.title"
                        item-value="Customer.id"
                        solo
                        v-model="filterValues.buyer"
                        @change="filterPurchaseOrders()"
                    ></v-autocomplete>
                    <v-autocomplete
                        :items="filterItems.supplier"
                        :loading="loading.filterItems.supplier"
                        :placeholder="$t('message.spl')"
                        class="filter-width-175 ml-2"
                        clearable
                        dense
                        hide-details="auto"
                        item-text="Supplier.title"
                        item-value="Supplier.id"
                        solo
                        v-model="filterValues.supplier"
                        @change="filterPurchaseOrders()"
                    ></v-autocomplete>
                    <v-menu
                        :close-on-content-click="false"
                        :nudge-right="40"
                        max-width="290px"
                        min-width="290px"
                        ref="wprDateMenu"
                        transition="scale-transition"
                        v-model="dateFilterMenus.ets"
                    >
                        <template v-slot:activator="{ on }">
                            <v-text-field
                                :value="computedFilterDates('ets')"
                                :placeholder="$t('message.ets')"
                                append-icon="event"
                                clearable
                                class="force-text-left filter-width-225 ml-2"
                                hint="DD/MM/YYYY format"
                                hide-details
                                background-color="white"
                                persistent-hint
                                readonly
                                solo
                                v-on="on"
                                @click:clear="resetSearch('ets')"
                                @click:append="dateFilterMenus.ets = true"
                            ></v-text-field>
                        </template>
                        <v-date-picker
                            first-day-of-week="1"
                            locale-first-day-of-year="4"
                            range
                            show-week
                            v-model="searchValues.ets"
                            @input="closeDateFilterMenu('ets')"
                            @change="searchPurchaseOrders('ets')"
                        ></v-date-picker>
                    </v-menu>
                    <v-menu
                        :close-on-content-click="false"
                        :nudge-right="40"
                        max-width="290px"
                        min-width="290px"
                        ref="wprDateMenu"
                        transition="scale-transition"
                        v-model="dateFilterMenus.etd"
                    >
                        <template v-slot:activator="{ on }">
                            <v-text-field
                                :value="computedFilterDates('etd')"
                                :placeholder="$t('message.etd')"
                                append-icon="event"
                                clearable
                                class="force-text-left filter-width-225 ml-2"
                                hint="DD/MM/YYYY format"
                                hide-details
                                background-color="white"
                                persistent-hint
                                readonly
                                solo
                                v-on="on"
                                @click:clear="resetSearch('etd')"
                                @click:append="dateFilterMenus.etd = true"
                            ></v-text-field>
                        </template>
                        <v-date-picker
                            first-day-of-week="1"
                            locale-first-day-of-year="4"
                            range
                            show-week
                            v-model="searchValues.etd"
                            @input="closeDateFilterMenu('etd')"
                            @change="searchPurchaseOrders('etd')"
                        ></v-date-picker>
                    </v-menu>
                    <v-menu
                        :close-on-content-click="false"
                        :nudge-right="40"
                        max-width="290px"
                        min-width="290px"
                        ref="wprDateMenu"
                        transition="scale-transition"
                        v-model="dateFilterMenus.eta"
                    >
                        <template v-slot:activator="{ on }">
                            <v-text-field
                                :value="computedFilterDates('eta')"
                                :placeholder="$t('message.eta')"
                                append-icon="event"
                                clearable
                                class="force-text-left filter-width-225 ml-2"
                                hint="DD/MM/YYYY format"
                                hide-details
                                background-color="white"
                                persistent-hint
                                readonly
                                solo
                                v-on="on"
                                @click:clear="resetSearch('eta')"
                                @click:append="dateFilterMenus.eta = true"
                            ></v-text-field>
                        </template>
                        <v-date-picker
                            first-day-of-week="1"
                            locale-first-day-of-year="4"
                            range
                            show-week
                            v-model="searchValues.eta"
                            @input="closeDateFilterMenu('eta')"
                            @change="searchPurchaseOrders('eta')"
                        ></v-date-picker>
                    </v-menu>
                    <v-btn
                        :loading="loading.clear"
                        class="ml-2 px-2"
                        @click="clearFilters"
                    >{{ $t('message.clear') }}</v-btn>
                </div>
                <v-spacer></v-spacer>
                <ExportTableJson
                    :export-conditions="exportConditions"
                    :export-data="PurchaseOrders"
                    :export-fields="headers"
                    :export-source="'purchaseorders'"
                    class="ml-3"
                ></ExportTableJson>
            </v-row>
        </v-sheet>
        <v-overlay
                :value="loading.purchaseOrders"
                absolute
                opacity="0.15"
        >
            <v-row>
                <v-col class="text-center">
                    <v-progress-circular
                            color="primary"
                            indeterminate
                            size="40"
                            width="6"
                    ></v-progress-circular>
                </v-col>
            </v-row>
        </v-overlay>
        <v-data-table
            :footer-props="{
                itemsPerPageOptions: [10,25,50,-1],
                showFirstLastPage: true
            }"
            :headers="headers"
            :hide-default-footer="hideDefaultFooter"
            :items="PurchaseOrders"
            :options="tableOptions"
            :server-items-length="totalPurchaseOrders"
            calculate-widths
            class="mt-3 appic-table-light specification-table"
            dense
            id="poTable"
            item-key="Purchaseorder.id"
            @update:options="updateDataTable"
        >
            <template v-slot:top>
                <div class="d-flex flex-row pt-0 pb-2 align-center">
                    <div class="font-sm">
                        <v-icon small color="green lighten-1">fiber_manual_record</v-icon>{{ $t('message.poSigned') }}
                    </div>
                    <div class="font-sm ml-3">
                        <v-icon small color="blue darken-4">fiber_manual_record</v-icon>{{ $t('message.poUnsigned') }}
                    </div>
                    <div class="font-sm ml-3">
                        <v-icon small color="yellow lighten-3">fiber_manual_record</v-icon>{{ $t('message.whPoUnsigned') }}
                    </div>
                    <v-spacer></v-spacer>
                    <span class="font-sm grey--text">{{ $t('message.noteDateFiltersAreRanges') }}</span>
                </div>
            </template>
            <template v-slot:item.Purchaseorder.podate="{ item }">
                {{ item.Purchaseorder.podate != '0000-00-00' ? formatDate(item.Purchaseorder.podate) : '' }}
            </template>
            <template v-slot:item.Purchaseorder.title="{ item }">
                <span class="text-no-wrap font-weight-bold">{{ item.Purchaseorder.title }}</span>
            </template>
            <template v-slot:item.Purchaseorder.Contract.isposigned="{ item }">
                <div class="text-center">
                    <v-icon small color="green lighten-1" v-if="item.Purchaseorder.Contract.isposigned == 1">fiber_manual_record</v-icon>
                    <v-icon small color="blue darken-4" v-if="item.Purchaseorder.Contract.isposigned != 1 && item.Purchaseorder.Contract.salestype_id != 3">fiber_manual_record</v-icon>
                    <v-icon small color="yellow lighten-3" v-if="item.Purchaseorder.Contract.isposigned != 1 && item.Purchaseorder.Contract.salestype_id == 3">fiber_manual_record</v-icon>
                </div>
            </template>
            <template v-slot:item.Purchaseorder.Wpr.title="{ item }">
                <span class="text-no-wrap">{{ item.Purchaseorder.Wpr.title }}</span>
                <span class="text-no-wrap">{{ item.Purchaseorder.Wpr.title }}</span>
            </template>
            <template v-slot:item.Purchaseorder.Customer.title="{ item }">
                {{ item.Purchaseorder.Customer.otsname != '' ? item.Purchaseorder.Customer.otsname : item.Purchaseorder.Customer.title }}
            </template>
            <template v-slot:item.Purchaseorder.Supplier.title="{ item }">
                <span>{{ item.Purchaseorder.Supplier.code != '' ? item.Purchaseorder.Supplier.code : item.Purchaseorder.Supplier.title }}</span>
            </template>
            <template v-slot:item.Purchaseorder.Contract.species="{ item }">
                <div class="text-no-wrap truncate" v-if="item.Purchaseorder.Contract.version == null">{{ item.Purchaseorder.Contract.species }}</div>
                <div class="d-flex flex-column" v-if="item.Purchaseorder.Contract.version != null">
                    <div :class="JSON.parse(item.Purchaseorder.Contract.species_ids).length > 1 ? 'font-xs ' : ''" v-for="species in contractSpecies(JSON.parse(item.Purchaseorder.Contract.species_ids)) ">{{ species }}</div>
                </div>
            </template>
            <template v-slot:item.Purchaseorder.Contract.grade="{ item }">
                <div class="text-no-wrap truncate" v-if="item.Purchaseorder.Contract.version == null">{{ item.Purchaseorder.Contract.grade }}</div>
                <div class="d-flex flex-column" v-if="item.Purchaseorder.Contract.version != null">
                    <div :class="(JSON.parse(item.Purchaseorder.Contract.grade_ids).length > 1 ? 'font-xs ' : '') + 'text-no-wrap truncate'" v-for="grade in contractGrades(JSON.parse(item.Purchaseorder.Contract.grade_ids)) ">{{ grade }}</div>
                </div>
            </template>
            <template v-slot:item.Purchaseorder.Contract.size="{ item }">
                <div class="text-no-wrap truncate" v-if="item.Purchaseorder.Contract.version == null">{{ item.Purchaseorder.Contract.size }}</div>
                <div class="d-flex flex-column" v-if="item.Purchaseorder.Contract.version != null">
                    <div :class="(JSON.parse(item.Purchaseorder.Contract.size_ids).length > 1 ? 'font-xs ' : '') + 'text-no-wrap truncate'" v-for="size in contractSizes(JSON.parse(item.Purchaseorder.Contract.size_ids))">{{ size }}</div>
                    <div v-if="item.Purchaseorder.Contract.size_ids == null">{{ item.Purchaseorder.Contract.size }}</div>
                </div>
            </template>
            <template v-slot:item.Purchaseorder.Contract.containersize_id="{ item }">
                <div class="text-no-wrap">
                    <span v-if=" [1,2,3,5].includes(item.Purchaseorder.Contract.containersize_id) ">{{ item.Purchaseorder.Contract.containercount }}</span>
                    <span v-if=" [1,2,3,5].includes(item.Purchaseorder.Contract.containersize_id) "> X </span>
                    <span>{{ getContainerSize(item.Purchaseorder.Contract.containersize_id) }}</span>
                </div>
            </template>
            <template v-slot:item.Purchaseorder.Contract.cost="{ item }">
                <div class="flex-row">
                    <span class="pr-1" v-if="item.Purchaseorder.Contract.version == null">{{ currency(item.Purchaseorder.Contract.currency_id) }}</span>
                    <span class="pr-1" v-else-if="item.Purchaseorder.Contract.version != null">{{ currency(item.Purchaseorder.currency_id) }}</span>
                    <span class="text-right">{{ formatThisNumber(item.Purchaseorder.Contract.cost) }}</span>
                </div>
            </template>
            <template v-slot:item.Purchaseorder.Contract.shipweekdate="{ item }">
                {{ item.Purchaseorder.Contract.shipweekdate != '0000-00-00' ? formatDate(item.Purchaseorder.Contract.shipweekdate) : '' }}
            </template>
            <template v-slot:item.Purchaseorder.Contract.shipmentstatus_id="{ item }">
                <span class="text-no-wrap" v-if="item.Purchaseorder.Contract.shipmentstatus_id == 6">
                    <v-icon x-small color="grey" class="mr-1">fas fa-ship</v-icon><span :class="item.Purchaseorder.Contract.shipmentstatus_text && item.Purchaseorder.Contract.shipmentstatus_text.length > 10 ? 'font-xs' : ''">{{ getShipmentStatus(item.Purchaseorder.Contract.shipmentstatus_id, item.Purchaseorder.Contract.shipmentstatus_text) }}</span>
                </span>
                <span v-else>
                    {{ getShipmentStatus(item.Purchaseorder.Contract.shipmentstatus_id, item.Purchaseorder.Contract.shipmentstatus_text) }}
                </span>
            </template>
            <template v-slot:item.Purchaseorder.Contract.etsdate="{ item }">
                {{ item.Purchaseorder.Contract.etsdate != '0000-00-00' ? formatDate(item.Purchaseorder.Contract.etsdate) : '' }}
            </template>
            <template v-slot:item.Purchaseorder.Contract.etddate="{ item }">
                {{ item.Purchaseorder.Contract.etddate != '0000-00-00' ? formatDate(item.Purchaseorder.Contract.etddate) : '' }}
            </template>
            <template v-slot:item.Purchaseorder.Contract.etadate="{ item }">
                {{ item.Purchaseorder.Contract.etadate != '0000-00-00' ? formatDate(item.Purchaseorder.Contract.etadate) : '' }}
            </template>
            <template v-slot:item.Supplierpayment.status="{ item }">
                <span :class="item.Supplierpayment.status && item.Supplierpayment.status.length > 10 ? 'font-xs' : 'font-sm'">{{ item.Supplierpayment.status }}</span>
            </template>
            <template v-slot:item.Purchaseorder.id="{ item }">
                <v-menu>
                    <template v-slot:activator="{ on: menu }">
                        <v-tooltip bottom>
                            <template v-slot:activator="{ on: tooltip }">
                                <v-btn icon v-on="{ ...tooltip, ...menu}" class="ml-1">
                                    <v-icon>more_vert</v-icon>
                                </v-btn>
                            </template>
                            <span>{{ $t('message.moreActions') }}</span>
                        </v-tooltip>
                    </template>
                    <v-list dense>
                        <v-list-item class="font-sm" @click="updatePurchaseOrder(item.Purchaseorder.id)" v-if="$can('update','Po')"><v-icon color="black lighten-1" small class="mr-1">edit</v-icon>{{ $t('message.updatePurchaseOrder') }}</v-list-item>

                        <!--added 2021-08-06-->
                        <v-list-item class="font-sm" @click="updateContract(item.Purchaseorder.Contract.id)" v-if="$can('update','Contract')"><v-icon color="black lighten-1" small class="mr-1">edit</v-icon>{{ $t('message.updateContract') }}</v-list-item>
                        <v-list-item class="font-sm" @click="updateShipment(item.Purchaseorder.Contract.id)" v-if="[1,2].includes(item.Purchaseorder.Contract.salestatus_id) && $can('update','Shipment')"><v-icon color="black lighten-1" small class="mr-1">edit</v-icon>{{ $t('message.updateShipment') }}</v-list-item>
                        <v-list-item class="font-sm" @click="updateShippingInstruction(item.Purchaseorder.Sda.id)" v-if="item.Purchaseorder.Sda.id != null && $can('update','Po')"><v-icon color="black lighten-1" small class="mr-1">edit</v-icon>{{ $t('message.updateShippingInstruction') }}</v-list-item>
                        <v-list-item class="font-sm" @click="updatePackingList(item.Purchaseorder.Pldo.id)" v-if="item.Purchaseorder.Pldo.id != null && $can('update','Shipment')"><v-icon color="black lighten-1" small class="mr-1">edit</v-icon>{{ $t('message.updatePackingList') }}</v-list-item>
                        <v-list-item class="font-sm" @click="updateInvoice(item.Purchaseorder.Invoice.id)" v-if="[1,5].includes(item.Purchaseorder.Contract.salestype_id) && item.Purchaseorder.Invoice.id != null && $can('update','Invoice')"><v-icon color="black lighten-1" small class="mr-1">edit</v-icon>{{ $t('message.updateInvoice') }}</v-list-item>
                        <v-list-item class="font-sm" @click="updatePayments(item.Purchaseorder.Contract.id)" v-if="$can('update','Payment')"><v-icon color="black lighten-1" small class="mr-1">edit</v-icon>{{ $t('message.updatePayments') }}</v-list-item>
                        <!---->

                        <v-list-item class="font-sm" @click="cancelPurchaseOrder(item.Purchaseorder.id, item.Purchaseorder.title)" v-if="$can('delete','Po')"><v-icon small class="mr-1" color="red lighten-1">cancel</v-icon>{{ $t('message.cancelPurchaseOrder') }}</v-list-item>
<!--                        <v-list-item class="font-sm" @click="viewPdf(item.Purchaseorder.id)" v-if="$can('read','Po')"><v-icon color="red lighten-1" small class="mr-2">fas fa-file-pdf</v-icon>{{ $t('message.pdf') }}</v-list-item>-->
                        <PrintPurchaseOrderButton
                            :update-mode="true"
                            :list-mode="true"
                            :purchase-order-id="item.Purchaseorder.id"
                            :purchase-order-title="item.Purchaseorder.title"
                            :self-list="true"
                            v-if="$can('read','Po')"
                        />
                        <v-list-item class="font-sm" @click="viewSignedPdf(item)" v-if="(item.Purchaseorder.TmsSignedPurchaseOrder.location != null || item.Purchaseorder.SignedPurchaseOrder.location != null) && $can('read','Po')"><v-icon color="red lighten-1" small class="mr-2">fas fa-file-pdf</v-icon>{{ $t('message.signedPo') }}</v-list-item>
                    </v-list>
                </v-menu>
            </template>
        </v-data-table>
        <SimpleAlert
                :alert_message.sync="dialogs.error_message"
                :dialog.sync="dialogs.error"
                @dialog-closed="dialogClosed"
        ></SimpleAlert>
    </div>
</template>

<script>
    import {mapGetters, mapActions, mapMutations} from "vuex";
    import {mapFields, mapMultiRowFields} from "vuex-map-fields";
    import {formatDate} from "Helpers/helpers";
    // import SimpleAlert from "./SimpleAlert";
    import {numberFormat} from "../../helpers/helpers";
    import { v4 as uuidv4 } from 'uuid';
    import {api} from "Api";

    const SimpleAlert = () => import("./SimpleAlert");
    const ExportTableJson = () => import("./ExportTableJson");
    const PrintPurchaseOrderButton = () => import("Components/Appic/Printing/PrintPurchaseOrderButton");

    export default {
        name: "PurchaseOrdersListingNew",
        components: {ExportTableJson, SimpleAlert, PrintPurchaseOrderButton},
        data() {
            return {
                currentFilter: null,
                currentSearch: null,
                dateFilterMenus: {
                    ets: false,
                    etd: false,
                    eta: false
                },
                dialogs: {
                    error:  false,
                    error_message: ""
                },
                exportConditions: {},
                filterItems: {
                    buyer: [],
                    status: [],
                    supplier: []
                },
                filterOptions: {},
                filterValues: {
                    buyer: null,
                    status: 0,
                    supplier: null
                },
                hideDefaultHeader: false,
                hideDefaultFooter: false,
                loading: {
                    filter: {
                        buyers: false,
                        suppliers: false
                    },
                    filterItems: {
                        buyer: null,
                        status: null,
                        supplier: null
                    },
                    search: false,
                    purchaseOrders: false
                },
                searching: false,
                searchField: 'Purchaseorder.title', //default search field
                searchTerm: null,
                searchValues: {
                    title: null,
                    ets: [],
                    etd: [],
                    eta: []
                },
                tableOptions: {},
            }
        },
        computed: {
            ...mapMultiRowFields('purchaseorder',{
                PurchaseOrders: 'current.PurchaseOrders'
            }),
            ...mapFields('purchaseorder',{
                totalPurchaseOrders: 'current.totalPurchaseOrders'
            }),
            ...mapFields('runtime',{
                openedTabs: 'openedTabs'
            }),
            ...mapGetters('currency', {
                allCurrencies: 'allCurrencies'
            }),
            ...mapGetters('grade', {
                allGrades: 'allGrades',
                findGradeById: 'findGradeById'
            }),
            ...mapGetters('purchaseorder',{
                purchaseOrderBuyers: 'purchaseOrderBuyers',
                purchaseOrderSuppliers: 'purchaseOrderSuppliers',
            }),
            ...mapGetters('size', {
                allSizes: 'allSizes',
                findSizeById: 'findSizeById'
            }),
            ...mapGetters('species', {
                allSpecies: 'allSpecies',
                findSpeciesById: 'findSpeciesById'
            }),
            ...mapGetters([
                'containerSizes',
                'currencies',
                'shipmentStatuses'
            ]),
            formatDate: () => formatDate,
            headers() {
                let headers = [
                    {
                        index: 0,
                        text: this.$t('message.actions'),
                        class: 'light-green lighten-3 py-0 pr-0 pl-1 font-sm text-left width-1-pct',
                        value: 'Purchaseorder.id',
                        searchable: false,
                        sortable: false
                    },
                    {
                        index: 1,
                        text: this.$t('message.date'),
                        value: 'Purchaseorder.podate',
                        class: 'light-green lighten-3 py-0 pr-0 pl-1 font-sm text-left',
                        searchable: false,
                        sortable: true
                    },
                    {
                        index: 2,
                        text: this.$t('message.purchaseOrderNumber'),
                        value: 'Purchaseorder.title',
                        class: 'light-green lighten-3 py-0 pr-0 pl-1 font-sm text-left',
                        searchable: false,
                        sortable: false
                    },
                    {
                        index: 3,
                        text: this.$t('message.status'),
                        value: 'Purchaseorder.Contract.isposigned',
                        class: 'light-green lighten-3 py-0 pr-0 pl-1 font-sm text-left',
                        searchable: false,
                        sortable: false
                    },
                    {
                        index: 4,
                        text: this.$t('message.buyer'),
                        value: 'Purchaseorder.Customer.title',
                        class: 'light-green lighten-3 py-0 pr-0 pl-1 font-sm text-left',
                        searchable: false,
                        sortable: true
                    },
                    {
                        index: 5,
                        text: this.$t('message.spl'),
                        value: 'Purchaseorder.Supplier.code',
                        class: 'light-green lighten-3 py-0 pr-0 pl-1 font-sm text-left width-5-pct',
                        searchable: false,
                        sortable: true
                    },
                    {
                        index: 6,
                        text: this.$t('message.supPi'),
                        value: 'Purchaseorder.Contract.supplier_pi_no',
                        class: 'light-green lighten-3 py-0 pr-0 pl-1 font-sm text-left text-no-wrap',
                        searchable: false,
                        sortable: false
                    },
                    {
                        index: 7,
                        text: this.$t('message.species'),
                        value: 'Purchaseorder.Contract.species',
                        class: 'light-green lighten-3 py-0 pr-0 pl-1 font-sm text-left',
                        searchable: false,
                        sortable: false
                    },
                    {
                        index: 8,
                        text: this.$t('message.grade'),
                        value: 'Purchaseorder.Contract.grade',
                        class: 'light-green lighten-3 py-0 pr-0 pl-1 font-sm text-left',
                        searchable: false,
                        sortable: false
                    },
                    {
                        index: 9,
                        text: this.$t('message.size'),
                        value: 'Purchaseorder.Contract.size',
                        class: 'light-green lighten-3 py-0 pr-0 pl-1 font-sm text-left',
                        searchable: false,
                        sortable: false
                    },
                    {
                        index: 10,
                        text: this.$t('message.container'),
                        value: 'Purchaseorder.Contract.containersize_id',
                        class: 'light-green lighten-3 py-0 pr-0 pl-1 font-sm text-left',
                        searchable: false,
                        sortable: false
                    },
                    {
                        index: 11,
                        text: this.$t('message.cost'),
                        value: 'Purchaseorder.Contract.cost',
                        class: 'light-green lighten-3 py-0 pr-0 pl-1 font-sm text-left',
                        searchable: false,
                        sortable: false
                    },
                    {
                        index: 12,
                        text: this.$t('message.osd'),
                        value: 'Purchaseorder.Contract.shipweekdate',
                        class: 'light-green lighten-3 py-0 pr-0 pl-1 font-sm text-left',
                        searchable: false,
                        sortable: true
                    },
                    {
                        index: 13,
                        text: this.$t('message.shipmentStatus'),
                        value: 'Purchaseorder.Contract.shipmentstatus_id',
                        class: 'light-green lighten-3 py-0 pr-0 pl-1 font-sm text-left',
                        searchable: false,
                        sortable: false
                    },
                    {
                        index: 14,
                        text: this.$t('message.ets'),
                        value: 'Purchaseorder.Contract.etsdate',
                        class: 'light-green lighten-3 py-0 pr-0 pl-1 font-sm text-left width-5-pct',
                        searchable: false,
                        sortable: true
                    },
                    {
                        index: 15,
                        text: this.$t('message.etd'),
                        value: 'Purchaseorder.Contract.etddate',
                        class: 'light-green lighten-3 py-0 pr-0 pl-1 font-sm text-left width-5-pct',
                        searchable: false,
                        sortable: true
                    },
                    {
                        index: 16,
                        text: this.$t('message.eta'),
                        value: 'Purchaseorder.Contract.etadate',
                        class: 'light-green lighten-3 py-0 pr-0 pl-1 font-sm text-left width-5-pct',
                        searchable: false,
                        sortable: true
                    },
                    {
                        index: 17,
                        text: this.$t('message.paymentSupplier'),
                        class: 'light-green lighten-3 py-0 pr-0 pl-1 font-sm text-left',
                        value: 'Supplierpayment.status',
                        searchable: false,
                        sortable: false
                    }
                ]
                return headers
            },
            searchFields() {
                return this.headers.filter((header)=>header.searchable === true)
            }
        },
        methods: {
            ...mapActions('currency',{
                getAllCurrencies: 'getAllCurrencies'
            }),
            ...mapActions('purchaseorder',{
                cancelPurchaseOrderById: 'cancelPurchaseOrderById',
                getAllPurchaseOrders: 'getAllPurchaseOrders',
                getAllPurchaseOrderBuyers: 'getAllPurchaseOrderBuyers',
                getAllPurchaseOrderSuppliers: 'getAllPurchaseOrderSuppliers',
                searchAllPurchaseOrders: 'searchAllPurchaseOrders'
            }),
            async cancelPurchaseOrder(val, title) {
                if(await this.$root.$confirm(this.$t('message.cancelPurchaseOrder') + ' ' + title, this.$t('message.confirmations.continuePurchaseOrderCancelAction'), {color: 'orange'})){
                    this.cancelPurchaseOrderById(val)
                        .then((status) => {
                            if(status == 'done'){
                                this.$toast.success(this.$t('message.successes.purchaseOrderDeleted'),
                                    {
                                        classes: ['icon-float-left'],
                                        icon: 'check_circle_outline'
                                    }
                                )
                                this.loadPurchaseOrders()
                            } else {
                                this.$toast.error(this.$t('message.errors.purchaseOrderNotDeleted'),
                                    {
                                        classes: ['icon-float-left'],
                                        icon: 'error_outline'
                                    }
                                )
                            }
                        })
                        .catch(()=>{
                            this.$toast.error(this.$t('message.errors.purchaseOrderNotDeleted'),
                                {
                                    classes: ['icon-float-left'],
                                    icon: 'error_outline'
                                }
                            )
                        })
                }
            },
            clearFilters(){
                this.loading.clear = true
                this.currentFilter = null
                this.currentSearch = null
                this.filterValues.buyer = null
                this.filterValues.status = 0
                this.filterValues.supplier = null
                this.searching = false
                this.searchValues.title = null
                this.searchValues.ets = []
                this.searchValues.etd = []
                this.searchValues.eta = []
                this.tableOptions.page = 1
                this.tableOptions.sortBy[0] = 'Purchaseorder.podate'
                this.tableOptions.sortDesc[0] = true
                this.loading.clear = false
                this.filterPurchaseOrders()
            },
            closeDateFilterMenu(filter) {
                //check if values are valid. don't allow reverse ranges where d1 > d2
                let d1 = new Date(this.searchValues[filter][0])
                let d2 = new Date(this.searchValues[filter][1])
                if(d1 > d2 && this.searchValues[filter].length == 2){
                    let newDate1 = this.searchValues[filter][1]
                    let newDate2 = this.searchValues[filter][0]
                    this.searchValues[filter] = [newDate1, newDate2]
                }
                if(this.searchValues[filter].length == 2) this.dateFilterMenus[filter] = false
            },
            computedFilterDates(date){
                let dates = ''
                if(this.searchValues[date] != null && this.searchValues[date][0] != null) dates = dates + formatDate(this.searchValues[date][0])
                if(this.searchValues[date] != null && this.searchValues[date][1] != null) dates = dates + " ~ " + formatDate(this.searchValues[date][1])
                return dates
            },
            contractGrades (ids){
                let array = []
                if(ids != null) {
                    ids.forEach((id) => {
                        let grade = this.findGradeById(id)
                        if (grade) {
                            array.push(grade.Grade.title)
                        }
                    })
                }
                return array
            },
            contractSizes (ids) {
                let array = []
                if(ids != null) {
                    ids.forEach((id) => {
                        let size = this.findSizeById(id)
                        if (size) {
                            array.push(size.Size.title)
                        }
                    })
                }
                return array
            },
            contractSpecies (ids) {
                let array = []
                if(ids != null) {
                    ids.forEach((id) => {
                        let species = this.findSpeciesById(id)
                        if (species) {
                            array.push(species.Species.title)
                        }
                    })
                }
                return array
            },
            currency(currency_id) {
                return this.allCurrencies.find((currency) => currency.Currency.id == currency_id)?.Currency?.code
            },
            dialogClosed () {
                this.dialogs.error = false;
                this.dialogs.error_message = ''
            },
            filterPurchaseOrders() {
                this.searchValues.title = null
                this.searchValues.ets = []
                this.searchValues.etd = []
                this.searchValues.eta = []
                let noValues = true
                const keys = Object.keys(this.filterValues)
                keys.forEach((key, index) => {
                    if(this.filterValues[key] != null) noValues = false
                })
                if(noValues == false) {
                    let conditions = []
                    if (this.filterValues.buyer != null) conditions.push({
                        field: 'Contract.customer_id',
                        value: this.filterValues.buyer
                    })
                    if (this.filterValues.status != null) conditions.push({
                        field: 'Purchaseorder.status_id',
                        value: this.filterValues.status
                    })
                    if (this.filterValues.supplier != null) conditions.push({
                        field: 'Contract.supplier_id',
                        value: this.filterValues.supplier
                    })

                    if (this.tableOptions.itemsPerPage == null) this.tableOptions.itemsPerPage = 10

                    if (_.isEqual(this.currentFilter, this.filterValues) === false) {
                        this.tableOptions.page = 1
                        this.currentFilter = _.cloneDeep(this.filterValues)
                    }

                    if(_.has(this.tableOptions, 'sortBy') == false) {
                        this.tableOptions['sortBy'] = ['Purchaseorder.podate']
                    }
                    if(this.tableOptions.sortBy.length == 0){
                        this.tableOptions['sortBy'] = ['Purchaseorder.podate']
                    }
                    if(_.has(this.tableOptions, 'sortDesc') == false){
                        this.tableOptions['sortDesc'] = [false]
                        if(this.tableOptions.sortBy[0] == 'Purchaseorder.podate') {
                            this.tableOptions['sortDesc'] = [true]
                        }
                    }
                    if(this.tableOptions.sortBy[0] == 'Purchaseorder.podate' && this.tableOptions.sortDesc.length == 0){
                        this.tableOptions['sortDesc'] = [true]
                    }

                    let order = [{field: this.tableOptions.sortBy[0], direction: this.tableOptions.sortDesc[0] ? 'DESC' : 'ASC'}]

                    let payload = {
                        tableOptions: this.tableOptions,
                        conditions: conditions,
                        order: order
                    }

                    this.hideDefaultFooter = false
                    this.loading.purchaseOrders = true;
                    this.searchAllPurchaseOrders(payload)
                        .then(() => {
                            this.loading.purchaseOrders = false;
                        })
                        .catch(()=>{
                            this.loading.purchaseOrders = false;
                        })
                        .finally(()=>{
                            this.loading.purchaseOrders = false;
                        })
                } else {
                    this.loadPurchaseOrders()
                }
            },
            formatThisNumber (value,format) {
                return numberFormat(value,format)
            },
            getContainerSize (val) {
                let container = this.containerSizes.find(containerSize => containerSize.Containersize.id == val)
                if(container){
                    return container.Containersize.title
                } else {
                    return null
                }
            },
            getShipmentStatus (id, text) {
                if(id) {
                    let status = this.shipmentStatuses.find(d => d.Shipmentstatus.id == id)
                    if(status?.Shipmentstatus?.hastext == 1){
                        if(id == 1){
                            if(parseInt(text) == text){
                                return status?.Shipmentstatus?.title + ': ' + text
                            } else {
                                return text
                            }
                        } else {
                            return text
                        }
                    } else {
                        return status?.Shipmentstatus?.title
                    }
                }
                return null
            },
            loadFilterItems(filter) {
                return new Promise((resolve, reject) => {
                    if(filter && filter.length > 0) {
                        this.loading.filterItems[filter] = true
                        api
                            .get('/purchaseorders/filter-options/' + filter)
                            .then(response => {
                                if(response.data.status == 'success') {
                                    this.filterItems[filter] = response.data.data

                                    //add All to some options
                                    if(filter == 'status'){
                                        this.filterItems[filter].unshift({Status: {id: 0, title: 'all' }})
                                    }

                                    this.loading.filterItems[filter] = false
                                    resolve('done')
                                } else {
                                    this.loading.filterItems[filter] = false
                                    reject('error')
                                }
                            })
                            .catch(error => {
                                this.loading.filterItems[filter] = false
                                reject(error)
                            })
                    } else {
                        reject('Filter empty')
                    }
                })
            },
            loadPurchaseOrders () {
                this.hideDefaultFooter = false
                this.loading.purchaseOrders = true;

                if(this.tableOptions.itemsPerPage == null) this.tableOptions.itemsPerPage = 10

                let payload = {
                    tableOptions: this.tableOptions
                }
                this.getAllPurchaseOrders(payload)
                    .then(()=>{
                        this.loading.purchaseOrders = false;
                    })
                    .catch(()=>{
                        this.loading.purchaseOrders = false;
                    })
                    .finally(()=>{
                        this.loading.purchaseOrders = false;
                    })
            },
            resetSearch (filter = null) {
                this.searching = false
                this.hideDefaultFooter = false
                this.filterValues.status = 0
                this.tableOptions.page = 1
                if(this.tableOptions.itemsPerPage == null) this.tableOptions.itemsPerPage = 10

                switch(filter){
                    case 'eta':
                    case 'etd':
                    case 'ets':
                        this.searchValues[filter] = []
                        break
                }

                this.tableOptions.sortBy = []
                this.tableOptions.sortDesc = []
                this.filterPurchaseOrders()
            },
            searchPurchaseOrders (field) {
                if(this.searchValues[field] == null || this.searchValues[field].length == 0){
                    this.dialogs.error = true
                    this.dialogs.error_message = this.$t('message.errors.noSearchTerm')
                } else {
                    this.searching = true
                    this.filterValues.buyer = null
                    this.filterValues.status = null
                    this.filterValues.supplier = null

                    const keys = Object.keys(this.searchValues)
                    keys.forEach((key, index) => {
                        if(key != field) {
                            if(['ets','etd','eta'].includes(key)){
                                this.searchValues[key] = []
                            } else {
                                this.searchValues[key] = null
                            }
                        }
                    })

                    let fieldName = null
                    let sortFieldName = null
                    switch(field){
                        case 'title':
                            fieldName = 'Purchaseorder.title'
                            sortFieldName = 'Purchaseorder.podate'
                            break
                        case 'ets':
                            fieldName = 'Contract.etsdate'
                            sortFieldName = 'Purchaseorder.Contract.etsdate'
                            break
                        case 'etd':
                            fieldName = 'Contract.etddate'
                            sortFieldName = 'Purchaseorder.Contract.etddate'
                            break
                        case 'eta':
                            fieldName = 'Contract.etadate'
                            sortFieldName = 'Purchaseorder.Contract.etadate'
                            break
                    }

                    this.loading.purchaseOrders = true

                    let payload = {}
                    let order = []

                    if (_.isEqual(this.currentSearch, this.searchValues) === false) {
                        //new search = new sort
                        order.push({field: fieldName, direction: field == 'title' ? 'ASC' : 'DESC'})
                        this.tableOptions.sortBy[0] = sortFieldName
                        this.tableOptions.sortDesc[0] = field == 'title' ? false : true
                        this.currentSearch = _.cloneDeep(this.searchValues)
                        //new search new page
                        this.tableOptions.page = 1
                    } else {
                        //same search new sort
                        if(this.tableOptions.sortBy.length == 0){
                            this.tableOptions.sortBy[0] = sortFieldName
                        }
                        order.push({field: this.tableOptions.sortBy[0], direction: this.tableOptions.sortDesc[0] ? 'DESC' : 'ASC'})
                    }

                    console.log(this.tableOptions)

                    if(fieldName != null) {
                        payload = {
                            tableOptions: this.tableOptions,
                            conditions: [
                                {
                                    field: fieldName,
                                    value: this.searchValues[field]
                                }
                            ],
                            order: order
                        }
                    } else {
                        payload = {
                            tableOptions: this.tableOptions,
                            conditions: [],
                            order: order
                        }
                    }
                    this.searchAllPurchaseOrders(payload)
                        .then(() => {
                            this.loading.purchaseOrders = false;
                        })
                        .catch(()=>{
                            this.loading.purchaseOrders = false;
                        })
                        .finally(()=>{
                            this.loading.purchaseOrders = false;
                        })
                }
            },
            updateContract (val) {
                let tab = window.open('/v1/contracts/update/' + val,'_blank')
                tab.name = 'tab_' + Math.floor(Math.random() * 10000)
                this.openedTabs.push(tab.name)
            },
            updateDataTable(options) {
                this.tableOptions = options
                if(this.searching === true)  {
                    // check which field is not empty
                    let field = null
                    if(this.searchValues.title != null) field = 'title'
                    if(this.searchValues.ets.length > 0) field = 'ets'
                    if(this.searchValues.eta.length > 0) field = 'eta'
                    if(this.searchValues.etd.length > 0) field = 'etd'
                    this.searchPurchaseOrders(field)
                } else {
                    this.filterPurchaseOrders()
                }
            },
            updateInvoice(val) {
                let tab = window.open('/v1/invoices/update/' + val,'_blank')
                tab.name = 'tab_' + Math.floor(Math.random() * 10000)
                this.openedTabs.push(tab.name)
            },
            updateDebitNote(val) {
                let tab = window.open('/v1/debitnotes/update/' + val,'_blank')
                tab.name = 'tab_' + Math.floor(Math.random() * 10000)
                this.openedTabs.push(tab.name)
            },
            updatePackingList(val) {
                let tab = window.open('/v1/packinglists/update/' + val,'_blank')
                tab.name = 'tab_' + Math.floor(Math.random() * 10000)
                this.openedTabs.push(tab.name)
            },
            updatePayments(val) {
                let tab = window.open('/v1/payments/update/contracts/' + val,'_blank')
                tab.name = 'tab_' + Math.floor(Math.random() * 10000)
                this.openedTabs.push(tab.name)
            },
            updatePurchaseOrder (val) {
                let tab = window.open('/v1/purchaseorders/update/' + val,'_blank')
                tab.name = 'tab_' + Math.floor(Math.random() * 10000)
                this.openedTabs.push(tab.name)
            },
            updateShippingInstruction(val){
                let tab = window.open('/v1/sdas/update/' + val,'_blank')
                tab.name = 'tab_' + Math.floor(Math.random() * 10000)
                this.openedTabs.push(tab.name)
            },
            updateShipment (val) {
                let tab = window.open('/v1/shipments/update/' + val,'_blank')
                tab.name = 'tab_' + Math.floor(Math.random() * 10000)
                this.openedTabs.push(tab.name)
            },
            viewPdf (purchaseOrderId) {
                let document  = encodeURIComponent('PO ' + this.PurchaseOrders.find( po => po.Purchaseorder.id == purchaseOrderId)?.Purchaseorder.title)
                let user = JSON.parse(localStorage.getItem('user'))
                let tab = window.open(
                    process.env.VUE_APP_PDF_SERVER
                    + '/api/render/'
                    + document
                    + '?url='
                    + process.env.VUE_APP_BASE_URL
                    + '/v1/purchaseorders/print/'
                    + purchaseOrderId
                    + '/'
                    + uuidv4()
                    + '&waitFor=h1&pdf.margin.top=15mm&pdf.margin.bottom=5mm&pdf.margin.left=20mm&pdf.margin.right=8mm&goto.timeout=0'
                    , "_blank");
                tab.name = 'tab_' + Math.floor(Math.random() * 10000)
                this.openedTabs.push(tab.name)
            },
            viewSignedPdf(item) {
                if(item.Purchaseorder.Contract.version != null && item.Purchaseorder.SignedPurchaseOrder.location != null){
                    let tab = window.open(item.Purchaseorder.SignedPurchaseOrder.location,"_blank")
                    tab.name = 'tab_' + Math.floor(Math.random() * 10000)
                    this.openedTabs.push(tab.name)
                } else {
                    let tab = window.open(item.Purchaseorder.TmsSignedPurchaseOrder.location,"_blank")
                    tab.name = 'tab_' + Math.floor(Math.random() * 10000)
                    this.openedTabs.push(tab.name)
                }
            }
        },
        // watch: {
        //     tableOptions: {
        //         handler(values) {
        //             if(this.searching === false) this.filterPurchaseOrders()
        //         },
        //         deep: true
        //     }
        // },
        created () {
            this.searchValues.title = null
            this.searchValues.ets = []
            this.searchValues.etd = []
            this.searchValues.eta = []
            this.filterValues.buyer = null
            this.filterValues.status = 0
            this.filterValues.supplier = null

            if(this.allCurrencies.length == 0) this.getAllCurrencies();

            if(this.filterValues.status == 0){
                this.filterPurchaseOrders()
            } else {
                this.loadPurchaseOrders()
            }

            if(this.filterItems.buyer.length == 0) this.loadFilterItems('buyer')
            if(this.filterItems.status.length == 0) this.loadFilterItems('status')
            if(this.filterItems.supplier.length == 0) this.loadFilterItems('supplier')
        }
    }
</script>

<style>
.filter-width-125 {
    width: 125px !important;
    max-width: 125px !important;
}
.filter-width-150 {
    width: 150px !important;
    max-width: 150px !important;
}
.filter-width-175 {
    width: 175px !important;
    max-width: 175px !important;
}
.filter-width-200 {
    width: 200px !important;
    max-width: 200px !important;
}
.filter-width-225 {
    width: 225px !important;
    max-width: 225px !important;
}
.filter-width-250 {
    width: 250px !important;
    max-width: 250px !important;
}
.font-xs {
    font-size: 0.60rem;
}
.text-start {
    padding-left: 3px !important;
    padding-top: 3px !important;
    padding-right: 3px !important;
    vertical-align: middle !important;
}
.truncate {
    display: inline-block;
    width: 100px !important;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}
.v-data-table td {
    padding: 0 3px;
    font-size: 0.8rem !important;
    line-height: 0.8rem;
}
</style>